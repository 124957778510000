import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Organization } from "@tamtam/api/db";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import SectionTitle from "../../../../components/section-title";
import { RouterOutput, trpc } from "../../../../services/trpc";
import { uploadFiles } from "../../../../services/upload";
import HandlebarTextField, {
  isLegalHandlebarAttribute,
} from "./handlebar-text-field";
import { Crisp } from "crisp-sdk-web";

type IFormInputs = Organization["config"] & {
  assets: {
    background: string | File | null;
  };
};

interface ConfigFormProps {
  organization: RouterOutput["organizations"]["getWithAdvisorAttributes"];
}

const ConfigForm: React.FC<ConfigFormProps> = ({ organization }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateConfig = trpc.organizations.updateConfig.useMutation();
  const { data: accessToken } = trpc.auth.readAccessToken.useQuery();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    watch,
    reset,
    setError,
  } = useForm<IFormInputs>({
    defaultValues: organization.config,
  });

  const onSubmit = async (values: IFormInputs) => {
    // Check that thank you message only contains known attributes
    if (values.appointment?.thank_you) {
      const matches = values.appointment.thank_you.match(/{{([^}]+)}}/g) || [];
      const unknownAttributes = matches
        .map((match) => match.slice(2, -2)) // Remove {{ and }}
        .filter(
          (attr) =>
            !isLegalHandlebarAttribute(
              attr,
              organization.advisorAttributes.map((a) => a.key)
            )
        );

      if (unknownAttributes.length > 0) {
        setError("appointment.thank_you", {
          message: `Le message contient une ou plusieurs valeurs dynamiques inconnues: ${unknownAttributes.join(", ")}`,
        });
        return;
      }
    }

    try {
      if (
        values.assets.background &&
        typeof values.assets.background !== "string"
      ) {
        const response = await uploadFiles("imageUploader", {
          files: [values.assets.background as File],
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        values.assets.background = response[0].url;
      }
      await updateConfig.mutateAsync({
        id: organization.id,
        config: {
          ...organization.config,
          ...values,
        },
      });
      enqueueSnackbar("Les paramètres de la borne ont bien été mis à jour", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: "error" });
    }
  };

  // Add this effect to reset the form when organization prop changes
  useEffect(() => {
    reset(organization.config);
  }, [organization, reset]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <SectionTitle>
        Paramètres
        <Button sx={{ marginLeft: 4 }} onClick={() => Crisp.chat.open()}>
          Besoin l'aide ?
        </Button>
      </SectionTitle>

      <Typography sx={{ mt: 3 }} variant="h6">
        Labels
      </Typography>
      <Controller
        name="welcome_title"
        control={control}
        defaultValue=""
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Titre page d'accueil — Première ligne"
            margin="normal"
            placeholder="Bienvenue"
            sx={{ width: "100%" }}
            error={!!errors.welcome_title}
            helperText={errors.welcome_title?.message}
          />
        )}
      />
      <Controller
        name="welcome_title_2"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Titre page d'accueil — Deuxième ligne"
            margin="normal"
            sx={{ width: "100%" }}
            error={!!errors.welcome_title_2}
            helperText={errors.welcome_title_2?.message}
          />
        )}
      />
      <Controller
        name="thank_you"
        control={control}
        defaultValue=""
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Message de remerciement"
            margin="normal"
            placeholder="Merci !"
            sx={{ width: "100%" }}
            error={!!errors.thank_you}
            helperText={errors.thank_you?.message}
          />
        )}
      />
      <Controller
        name="label_nom"
        control={control}
        defaultValue=""
        rules={{ required: "Ce champ est requis" }}
        render={({ field }) => (
          <TextField
            {...field}
            label='Libellé du champ "Indiquez votre nom"'
            margin="normal"
            placeholder="Merci !"
            sx={{ width: "100%" }}
            error={!!errors.label_nom}
            helperText={errors.label_nom?.message}
          />
        )}
      />

      <Typography sx={{ mt: 3 }} variant="h6">
        Deuxième champ de saisie visiteur (bouton de droite)
      </Typography>
      <Controller
        name="deuxieme_champs_activated"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} checked={field.value} />}
            label={'Activer la page "deuxième champs de saisie visiteur"'}
          />
        )}
      />
      {watch("deuxieme_champs_activated") && (
        <Controller
          name="deuxieme_champs_label"
          control={control}
          defaultValue=""
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Label du deuxième champs"
              margin="normal"
              placeholder="Indiquez la raison de votre visite"
              sx={{ width: "100%" }}
              error={!!errors.deuxieme_champs_label}
              helperText={errors.deuxieme_champs_label?.message}
            />
          )}
        />
      )}

      <Typography sx={{ mt: 3 }} variant="h6">
        J'ai un rendez-vous
      </Typography>
      <Controller
        name="appointment.activated"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} checked={field.value} />}
            label={'Activer la page "J\'ai un rendez-vous"'}
          />
        )}
      />
      {watch("appointment.activated") && (
        <>
          <Controller
            name="appointment.choose_advisor"
            control={control}
            defaultValue=""
            rules={{ required: "Ce champ est requis" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Message pour le choix du conseiller"
                margin="normal"
                placeholder="Veuillez choisir votre conseiller"
                sx={{ width: "100%" }}
                error={!!errors.appointment?.choose_advisor}
                helperText={errors.appointment?.choose_advisor?.message}
              />
            )}
          />
          <Controller
            name="appointment.label_have"
            control={control}
            defaultValue=""
            rules={{ required: "Ce champ est requis" }}
            render={({ field }) => (
              <TextField
                {...field}
                label={'Libellé du champ "J\'ai un rendez-vous"'}
                margin="normal"
                placeholder="J'ai un rendez-vous"
                sx={{ width: "100%" }}
                error={!!errors.appointment?.label_have}
                helperText={errors.appointment?.label_have?.message}
              />
            )}
          />
          <Controller
            name="appointment.label_not_have"
            control={control}
            defaultValue=""
            rules={{ required: "Ce champ est requis" }}
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  'Libellé du champ "Je n\'ai pas de rendez-vous" (bouton de droite)'
                }
                margin="normal"
                placeholder="Je n'ai pas de rendez-vous"
                sx={{ width: "100%" }}
                error={!!errors.appointment?.label_not_have}
                helperText={errors.appointment?.label_not_have?.message}
              />
            )}
          />

          <Controller
            name="appointment.thank_you"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <HandlebarTextField
                {...field}
                allowedAttributes={organization.advisorAttributes.map(
                  (e) => e.key
                )}
                label="Message de remerciement dans le cas d'un RDV"
                margin="normal"
                sx={{ width: "100%" }}
                error={!!errors.appointment?.thank_you}
                helperText={
                  errors.appointment?.thank_you?.message ||
                  "Ne touchez aux variables entre {{…}} que si vous savez les manipuler. Contactez-nous dans le chat en cas de problème."
                }
              />
            )}
          />
          <Typography sx={{ mt: 3 }} variant="h6">
            Deuxième champ de saisie visiteur pour les rendez-vous (bouton de
            gauche)
          </Typography>
          <Controller
            name="deuxieme_champs_appointment_activated"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label={
                  'Activer la page "deuxième champs de saisie visiteur" pour les rendez-vous'
                }
              />
            )}
          />
          {watch("deuxieme_champs_appointment_activated") && (
            <Controller
              name="deuxieme_champs_appointment_label"
              control={control}
              defaultValue=""
              rules={{ required: "Ce champ est requis" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Label du deuxième champs pour les rendez-vous"
                  margin="normal"
                  sx={{ width: "100%" }}
                  error={!!errors.deuxieme_champs_appointment_label}
                  helperText={errors.deuxieme_champs_appointment_label?.message}
                />
              )}
            />
          )}
        </>
      )}

      <Typography sx={{ mt: 3 }} variant="h6">
        Images
      </Typography>
      <Box sx={{ my: 2 }}>
        {watch("assets.background") && (
          <Box
            component="img"
            sx={{ maxHeight: 100 }}
            alt=""
            src={watch("assets.background")}
          />
        )}
        <Controller
          name="assets.background"
          control={control}
          render={({ field }) => (
            <input
              type="file"
              onChange={(e) => field.onChange(e.target.files?.[0])}
            />
          )}
        />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isDirty}
        message="Le formulaire contient des changements non sauvegardés"
      />
      <LoadingButton
        loading={isSubmitting}
        sx={{ mt: 3 }}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!isDirty || isSubmitting}
      >
        Mettre à jour
      </LoadingButton>
    </Box>
  );
};

export default ConfigForm;
