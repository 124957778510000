import React, { useState } from "react";
import {
  TextField as MuiTextField,
  CircularProgress,
  Box,
} from "@mui/material";
import Button from "./button";

interface TextFieldProps {
  value: string;
  setValue: (value: string) => void;
  submit: () => void;
  submitting: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  value,
  setValue,
  submit,
  submitting,
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <>
      {touched && (
        <MuiTextField
          value={value}
          sx={{
            margin: "-30px 10% 5% 10%",
            "& input": {
              height: 60,
              fontSize: "2em",
              textAlign: "center",
            },
          }}
        />
      )}
      <Box
        component="input"
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          background: "none",
          border: "0",
          outline: "none",
          opacity: 0,
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        onSelect={() => {
          setTouched(true);
        }}
        autoFocus
      />
      {touched && (
        <Button
          disabled={!value || submitting}
          action={submit}
          sx={{
            fontSize: "1.8em",
            height: 100,
            width: 300,
            margin: "auto",
            marginBottom: 20,
          }}
        >
          {submitting ? <CircularProgress color="primary" /> : "OK"}
        </Button>
      )}
    </>
  );
};

export default TextField;
