import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  createFileRoute,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { TRPCClientError } from "@trpc/client";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Background } from "../components/background";
import { trpc } from "../services/trpc";

interface IFormInputs {
  email: string;
  password: string;
}

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IFormInputs>();
  const navigate = useNavigate();
  const search = useSearch({ strict: false });
  const loginMutation = trpc.auth.login.useMutation();
  const { data: user } = trpc.auth.me.useQuery();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    try {
      await loginMutation.mutateAsync(data);
    } catch (err) {
      if (err instanceof TRPCClientError) {
        if (err.data.code === "UNAUTHORIZED") {
          setError("root", {
            type: "manual",
            message: "Email ou mot de passe invalide",
          });
        } else {
          setError("root", {
            type: "manual",
            message: "Une erreur inattendue est survenue",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      navigate({
        to: search.redirect ?? "/dashboard",
      });
    }
  }, [user, navigate, search.redirect]);

  return (
    <>
      <Background />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Paper
          sx={{
            width: "fit-content",
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Se connecter
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1, textAlign: "center" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              {...register("email", { required: "L'email est requis" })}
              error={!!errors.email || !!errors.root}
              helperText={errors.email?.message}
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Mot de passe"
              type="password"
              {...register("password", {
                required: "Le mot de passe est requis",
              })}
              error={!!errors.password || !!errors.root}
              helperText={errors.password?.message}
              autoComplete="current-password"
            />
            {errors.root && (
              <FormHelperText error>{errors.root.message}</FormHelperText>
            )}
            <FormHelperText sx={{ mt: 3 }}>
              <Link href="/reset-password">Mot de passe oublié ?</Link>
            </FormHelperText>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || loginMutation.isPending}
              sx={{ mt: 3 }}
            >
              {isSubmitting || loginMutation.isPending ? (
                <CircularProgress size={24} />
              ) : (
                "Se connecter"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export const Route = createFileRoute("/login")({
  component: Login,
  validateSearch: (search: Record<string, unknown>) => ({
    redirect: search.redirect as string | undefined,
  }),
});
