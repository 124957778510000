import { createFileRoute } from "@tanstack/react-router";
import { trpc } from "../../../services/trpc";
import { useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { useUser } from "../../../hooks/use-user";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export const Route = createFileRoute("/dashboard/archive/")({
  component: () => <Archive />,
});

export function Archive() {
  const [currentDay, setCurrentDay] = useState(dayjs().format("YYYY-MM-DD"));
  const user = useUser();
  const deleteHistory = trpc.visits.delete.useMutation();
  const downloadArchive = trpc.visits.download.useMutation();
  const { data: visits = [] } = trpc.visits.findByDay.useQuery(
    {
      organizationId: user.organizationId!,
      day: dayjs(currentDay).toDate(),
    },
    {
      enabled: !!user.organizationId,
    }
  );

  const handlePreviousDay = () => {
    setCurrentDay(dayjs(currentDay).subtract(1, "day").format("YYYY-MM-DD"));
  };

  const handleNextDay = () => {
    const nextDay = dayjs(currentDay).add(1, "day");
    if (nextDay.isSameOrBefore(dayjs(), "day")) {
      setCurrentDay(nextDay.format("YYYY-MM-DD"));
    }
  };

  const handleDeleteHistory = async () => {
    const { count } = await deleteHistory.mutateAsync({
      organizationId: user.organizationId!,
      dryRun: true,
    });

    if (count === 0) {
      window.alert("Il n'y a pas de visites à supprimer");
      return;
    }

    if (!window.confirm(`Supprimer ${count} visites ?`)) {
      return;
    }

    await deleteHistory.mutateAsync({
      organizationId: user.organizationId!,
    });
  };

  const handleDownloadArchive = async () => {
    try {
      // Call the tRPC query to generate the CSV
      const csvData = await downloadArchive.mutateAsync({
        organizationId: user.organizationId!,
      });

      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a download link and trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data.csv";
      link.click();

      // Clean up the URL object after download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download CSV:", error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4" component="h1">
          Archive
        </Typography>
        {["SUPER_ADMIN", "ADMIN"].includes(user.type) && (
          <Box>
            <Button
              color="secondary"
              onClick={handleDeleteHistory}
              sx={{ mr: 2 }}
            >
              Supprimer l'historique
            </Button>

            <Button color="primary" onClick={handleDownloadArchive}>
              Télécharger l'historique
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography variant="body1">
          {dayjs(currentDay).format("DD/MM/YYYY")}
        </Typography>
        <IconButton onClick={handlePreviousDay}>
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextDay}
          disabled={dayjs(currentDay).isSame(dayjs(), "day")}
        >
          <ChevronRight />
        </IconButton>
      </Box>
      {visits.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            marginTop: 2,
          }}
        >
          <Typography align="center">
            Il n'y a pas eu de visites ce jour-ci
          </Typography>
        </Box>
      )}
      {visits.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Arrivé le</TableCell>
              <TableCell>Clôturé le</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visits.map(({ id, name, closedAt, createdAt }) => (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{dayjs(createdAt).format("lll")}</TableCell>
                <TableCell>
                  {closedAt ? dayjs(closedAt).format("lll") : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
