import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useUser } from "../../hooks/use-user";

export function NewFeaturesDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const email = useUser()?.email;

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        ✨ Tamtam évolue !
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          Nouveaux filtres pour les visites, navigation entre plusieurs
          organisations… Découvrez en 1 minute et en vidéo les dernières
          fonctionnalités de Tamtam.
        </Typography>
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            width: "100%",
          }}
        >
          <iframe
            src={`https://www.loom.com/embed/47a951b69fd84b4a8dacca534217ba86?anon_email=${email}`}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            data-loom-embed
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
