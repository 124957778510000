import { Box } from "@mui/material";

export function Background() {
  return (
    <Box
      sx={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        background: `url('/background.jpg') no-repeat center center fixed`,
        backgroundSize: "cover",
        top: 0,
        zIndex: -1,
      }}
    />
  );
}
