import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useUser } from "../../../hooks/use-user";
import { trpc } from "../../../services/trpc";
import ConfigForm from "./-components/config-form";

const Settings: React.FC = () => {
  const user = useUser();
  const { data: organization } = trpc.organizations.getWithAdvisorAttributes.useQuery({
    id: user.organizationId,
  });

  if (!user.organizationId) {
    return <div>Choisissez une organization</div>;
  }

  if (!organization) {
    return <div>Loading...</div>;
  }

  return <ConfigForm organization={organization} />;
};

export const Route = createFileRoute("/dashboard/settings/")({
  component: Settings,
});
