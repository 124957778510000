import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import { trpc } from "../../../../services/trpc";
export function VariablesSection({
  organizationId,
}: {
  organizationId: string;
}) {
  const { data: organization } = trpc.organizations.getSuperAdmin.useQuery({
    id: organizationId,
  });
  const createAttribute = trpc.advisorAttributes.create.useMutation();
  const updateAtributeLabel = trpc.advisorAttributes.updateLabel.useMutation();
  const updateAtributeKey = trpc.advisorAttributes.updateKey.useMutation();
  const deleteAttribute = trpc.advisorAttributes.delete.useMutation();
  const createValue = trpc.advisorAttributes.createValue.useMutation();
  const updateValue = trpc.advisorAttributes.updateValue.useMutation();
  const deleteValue = trpc.advisorAttributes.deleteValue.useMutation();

  if (!organization) return null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "30px auto 20px auto",
        }}
      >
        <Typography variant="h5">Variables</Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            const label = prompt("Nom de la variable ?");
            if (!label) return;
            createAttribute.mutate({
              organizationId,
              label,
            });
          }}
        >
          Ajouter une variable
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Clé</TableCell>
            <TableCell>Valeurs</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(organization.advisorAttributes, (i) =>
            i.label.toLowerCase()
          ).map((attribute) => (
            <TableRow key={attribute.id}>
              <TableCell>
                {attribute.label}{" "}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    const label = prompt("Nouveau nom ?", attribute.label);
                    if (!label) return;
                    updateAtributeLabel.mutate({
                      id: attribute.id,
                      label,
                    });
                  }}
                >
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>
                {attribute.key}{" "}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    const key = prompt("Nouvelle clé ?", attribute.key);
                    if (!key) return;
                    updateAtributeKey.mutate({
                      id: attribute.id,
                      key,
                    });
                  }}
                >
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>
                {attribute.advisorAttributeValues.map((value) => (
                  <Chip
                    key={value.id}
                    label={value.value}
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      const newValue = prompt("Nouvelle valeur ?", value.value);
                      if (!newValue) return;
                      updateValue.mutate({
                        id: value.id,
                        value: newValue,
                      });
                    }}
                    onDelete={() => {
                      if (confirm("Supprimer cette valeur ?")) {
                        deleteValue.mutate({ id: value.id });
                      }
                    }}
                  />
                ))}
                <Chip
                  label="+"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    const value = prompt("Nouvelle valeur ?");
                    if (!value) return;
                    createValue.mutate({
                      advisorAttributeId: attribute.id,
                      value,
                    });
                  }}
                  sx={{ marginRight: 1 }}
                />
              </TableCell>
              <TableCell>
                <IconButton color="secondary">
                  <Delete
                    onClick={async () => {
                      if (
                        confirm(
                          "Voulez-vous vraiment supprimer cette variable ?"
                        )
                      ) {
                        await deleteAttribute.mutateAsync({ id: attribute.id });
                      }
                    }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
