import React from "react";
import { Button as MUIButton } from "@mui/material";

interface ButtonProps {
  children: React.ReactNode;
  action: () => void;
  noPreventDefault?: boolean;
  className?: string;
  disabled?: boolean;
  sx?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  children,
  action,
  noPreventDefault,
  className,
  disabled,
  sx,
}) => (
  <MUIButton
    className={className}
    disabled={disabled}
    size="large"
    variant="contained"
    color="primary"
    sx={sx}
    onClick={() => {
      action();
    }}
    onTouchEnd={(e) => {
      if (!noPreventDefault) {
        e.preventDefault();
      }
      action();
    }}
  >
    {children}
  </MUIButton>
);

export default Button;
