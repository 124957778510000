import {
  Alert,
  AlertColor,
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useUser } from "../../../../hooks/use-user";
import { RouterOutput, trpc } from "../../../../services/trpc";

export function AvailableSection({
  organization,
}: {
  organization: RouterOutput["organizations"]["get"];
}) {
  const user = useUser();
  const advisorProfileId = user.advisorProfiles.find(
    (a) => a.organizationId === organization?.id
  )?.id;
  const { data: advisorProfile } = trpc.advisorProfiles.get.useQuery(
    {
      id: advisorProfileId!,
    },
    {
      enabled: !!advisorProfileId,
    }
  );
  const enableAdvisor = trpc.organizations.enableAdvisor.useMutation();

  if (!advisorProfile) return null;

  const available = advisorProfile.enabled;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {organization.config.appointment.activated && (
        <>
          <ToggleButtonGroup
            color="primary"
            value={available}
            exclusive
            onChange={(e, value) => {
              if (value === null) return;

              enableAdvisor.mutateAsync({
                organizationId: organization.id,
                userId: user.id,
                enable: value,
              });
            }}
            aria-label="Platform"
          >
            <ToggleButton value={true} color="primary">
              Disponible
            </ToggleButton>
            <ToggleButton value={false} color="secondary">
              Indisponible
            </ToggleButton>
          </ToggleButtonGroup>

          <div
            style={{
              display: "flex",
            }}
          >
            {available ? (
              <Alert
                severity="info"
                style={{
                  marginTop: 12,
                }}
              >
                Vous êtes <strong>visible</strong> sur la borne
              </Alert>
            ) : (
              <Alert
                severity="error"
                color={"secondary" as AlertColor}
                style={{
                  marginTop: 12,
                }}
              >
                Vous n'êtes <strong>pas visible</strong> sur la borne
              </Alert>
            )}
          </div>
        </>
      )}
    </Box>
  );
}
