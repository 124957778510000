import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useUser } from "../hooks/use-user";
import { trpc } from "../services/trpc";

const OrganizationSelector: React.FC = () => {
  const [selectedOrganization, setSelectedOrganization] = useState<string>("");
  const user = useUser();
  const { data: selectableOrgs, isLoading } =
    trpc.organizations.getSelectable.useQuery();
  const selectOrganization = trpc.organizations.select.useMutation();

  useEffect(() => {
    if (selectableOrgs && user.organization) {
      setSelectedOrganization(user.organization.id);
    }
  }, [selectableOrgs, user.organization]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newOrgId = event.target.value;
    setSelectedOrganization(newOrgId);
    selectOrganization.mutate({ organizationId: newOrgId });
  };

  if (isLoading) return <div>Loading organizations...</div>;

  if (!selectableOrgs) return null;

  if (user.type === "ADVISOR" || selectableOrgs.length <= 1) {
    return (
      <Typography sx={{ color: "white", margin: "0 20px" }}>
        {user.organization?.name || "No organization"}
      </Typography>
    );
  }

  return (
    <FormControl sx={{ width: "300px", margin: "0 10px" }}>
      <Select
        value={selectedOrganization}
        onChange={handleChange}
        displayEmpty
        sx={{
          color: "white",
          height: "40px",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
        }}
      >
        <MenuItem value="" disabled>
          Select Organization
        </MenuItem>
        {orderBy(selectableOrgs, (i) => i.name.toLowerCase()).map((org) => (
          <MenuItem key={org.id} value={org.id}>
            {org.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganizationSelector;
