import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  createFileRoute,
  redirect,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { Background } from "../components/background";
import { trpc } from "../services/trpc";
import { useMount } from "react-use";

const schema = z.object({
  password: z
    .string()
    .min(8, "Le mot de passe doit mesurer au minimum 8 caractères")
    .regex(/[A-Z]/, "Le mot de passe doit contenir au moins une majuscule")
    .regex(/\d/, "Le mot de passe doit contenir au moins un chiffre")
    .regex(/[a-z]/, "Le mot de passe doit contenir au moins une minuscule"),
});

type FormInputs = z.infer<typeof schema>;

function NewPassword() {
  const search = useSearch({ strict: false });
  const navigate = useNavigate();
  const newPassword = trpc.auth.newPassword.useMutation();
  const checkNewPasswordToken = trpc.auth.checkNewPasswordToken.useMutation();
  const { data: user } = trpc.auth.me.useQuery();

  const token = search.reset_token;

  useEffect(() => {
    if (!token) {
      navigate({ to: "/reset-password" });
    }
  }, [token, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormInputs>({
    resolver: zodResolver(schema),
  });

  useMount(async () => {
    try {
      await checkNewPasswordToken.mutateAsync({
        token: token as string,
      });
    } catch {
      navigate({
        to: "/login",
        search: { redirect: undefined },
        replace: true,
      });
    }
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await newPassword.mutateAsync({
        token: token as string,
        password: data.password,
      });
    } catch {
      setError("password", {
        type: "manual",
        message: "Une erreur est survenue",
      });
    }
  };

  useEffect(() => {
    if (user && newPassword.isSuccess) {
      navigate({
        to: "/dashboard",
      });
    }
  }, [user, navigate, newPassword.isSuccess]);

  return (
    <>
      <Background />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Paper
          sx={{
            width: { xs: "90%", sm: 400 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nouveau mot de passe
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 1, width: "100%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="new-password"
              error={!!errors.password}
              {...register("password")}
            />
            {errors.password && (
              <FormHelperText error>{errors.password.message}</FormHelperText>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Valider"}
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export const Route = createFileRoute("/new-password")({
  component: NewPassword,
  validateSearch: (search: Record<string, unknown>) => ({
    reset_token: search.reset_token as string,
  }),
  beforeLoad: async ({ context, search }) => {
    if (context.user) {
      throw redirect({
        to: "/dashboard",
        replace: true,
      });
    }

    if (!search.reset_token) {
      throw redirect({
        to: "/reset-password",
        replace: true,
      });
    }
  },
});
