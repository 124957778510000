import React, { useState, useRef, useEffect } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  Button,
} from "@mui/material";
import { orderBy } from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";

interface AdvisorPickerProps {
  goToStep: (step: number) => void;
  advisors: Array<{
    userId: string;
    displayName: string;
    pushedToTopAt: Date | null;
  }>;
  advisorId?: string;
  setAdvisorId: (advisorId: string) => void;
}

const AdvisorPicker: React.FC<AdvisorPickerProps> = ({
  goToStep,
  advisors,
  advisorId,
  setAdvisorId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAdvisors, setFilteredAdvisors] = useState(advisors);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const filtered = advisors.filter((advisor) =>
      advisor.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredAdvisors(filtered);
  }, [searchTerm, advisors]);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setIsOverflowing(
          containerRef.current.scrollHeight > containerRef.current.clientHeight
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [filteredAdvisors]);

  const scrollList = (direction: "up" | "down") => {
    if (containerRef.current) {
      const scrollAmount = direction === "up" ? -100 : 100;
      containerRef.current.scrollTop += scrollAmount;
    }
  };

  if (!advisors.length) {
    goToStep(2);
    return null;
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          ref={containerRef}
          sx={{
            maxHeight: 215,
            bgcolor: "grey.200",
            overflowY: "auto",
            p: 2,
            flexGrow: 1,
          }}
        >
          <RadioGroup
            aria-label="advisor"
            name="advisor"
            value={advisorId || ""}
            onChange={(e) => {
              setAdvisorId(e.target.value);
            }}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              height: "100%",
            }}
          >
            {orderBy(filteredAdvisors, ["pushedToTopAt"], ["desc"]).map(
              ({ userId, displayName }) => (
                <FormControlLabel
                  key={userId}
                  value={userId}
                  control={<Radio color="primary" />}
                  label={
                    <Typography sx={{ fontSize: "1.8em" }}>
                      {displayName}
                    </Typography>
                  }
                />
              )
            )}
          </RadioGroup>
        </Box>
        {isOverflowing && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              ml: 1,
            }}
          >
            <IconButton
              color="primary"
              onClick={() => scrollList("up")}
              sx={{ border: "1px solid", borderRadius: "50%", p: "6px", mb: 2 }}
            >
              <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => scrollList("down")}
              sx={{ border: "1px solid", borderRadius: "50%", p: "6px" }}
            >
              <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Recherche"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mr: 2, flexGrow: 1, height: "100%" }}
        />
        <Button
          variant="contained"
          disabled={!advisorId}
          onClick={() => goToStep(2)}
          sx={{ fontSize: "1.8em", height: 100, width: 300, m: "auto" }}
        >
          OK
        </Button>
      </Box>
    </>
  );
};

export default AdvisorPicker;
