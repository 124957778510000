import { useEffect } from "react";

const useVersionCheck = (interval = 60000) => {
  useEffect(() => {
    const checkForNewVersion = async () => {
      try {
        // Fetch the latest version from the server, bypassing the cache
        const response = await fetch("/version.txt", { cache: "no-store" });
        const latestVersion = await response.text();

        // Get the currently stored version from localStorage
        const currentVersion = localStorage.getItem("app-version");

        // If the versions don't match, a new deployment is available
        if (currentVersion !== latestVersion) {
          // Store the new version and reload the page
          localStorage.setItem("app-version", latestVersion);
          window.location.reload();
        }
      } catch (error) {
        console.error("Failed to check app version:", error);
      }
    };

    // On initial load, fetch and store the current version if not already set
    const initializeVersion = async () => {
      if (!localStorage.getItem("app-version")) {
        const response = await fetch("/version.txt", { cache: "no-store" });
        const initialVersion = await response.text();
        localStorage.setItem("app-version", initialVersion);
      }
    };

    // Run the initial version check and set up the interval for polling
    initializeVersion();
    const intervalId = setInterval(checkForNewVersion, interval);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [interval]);
};

export default useVersionCheck;
