import { useAtom } from "jotai";
import { useEffect } from "react";

import { websocketAtom } from "../atoms/websocketAtom";
import { trpc } from "../services/trpc";

let reconnectTimeout: ReturnType<typeof setTimeout> | null = null;

function connect() {
  if (reconnectTimeout !== null) {
    clearTimeout(reconnectTimeout);
    reconnectTimeout = null;
  }

  const ws = new WebSocket(import.meta.env.VITE_WS_SERVER_URL);

  ws.onopen = function () {
    console.info("Socket is open");
    if (reconnectTimeout !== null) {
      clearTimeout(reconnectTimeout);
      reconnectTimeout = null;
    }
  };

  ws.onclose = function (e) {
    console.info(
      "Socket is closed. Reconnect will be attempted in 5 seconds.",
      e.reason
    );

    if (reconnectTimeout === null) {
      reconnectTimeout = setTimeout(function () {
        reconnectTimeout = null;
        connect();
      }, 5 * 1000); // 5 seconds
    }
  };

  ws.onerror = function (error: Event) {
    console.error("Socket encountered error");
    ws.close();
  };

  return ws;
}

export const useWebSocket = () => {
  const [, setWebSocket] = useAtom(websocketAtom);
  const { data: user } = trpc.auth.me.useQuery();

  useEffect(() => {
    if (!user?.id) {
      // If user is not connected, don't open the WebSocket
      return;
    }

    const ws = connect();

    // Store the WebSocket connection in the atom
    setWebSocket(ws);

    // Cleanup the WebSocket when the component unmounts or user logs out
    return () => {
      ws.close();
      setWebSocket(null);
    };
  }, [setWebSocket, user?.id]);
};
