import { TextField, TextFieldProps } from "@mui/material";
import React, { useCallback, useRef } from "react";
import { useMount } from "react-use";

/**
 * Check if a handlebar attribute is legal
 * @param attribute - The attribute to check
 * @param allowedAttributes - The allowed attributes
 * @returns True if the attribute is legal, false otherwise
 */
// eslint-disable-next-line
export const isLegalHandlebarAttribute = (
  attribute: string,
  allowedAttributes: string[]
) =>
  allowedAttributes.includes(attribute) ||
  (attribute.match(/^#if\s+(\w+)$/) && allowedAttributes.includes(RegExp.$1)) ||
  attribute.match(/^else$|^\/if$/);

const HandlebarTextField: React.FC<
  TextFieldProps & {
    allowedAttributes: string[];
  }
> = ({ allowedAttributes, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleHandlebarsHighlight = useCallback(
    (value: string) => {
      const parts = value.split(/{{([^}]+)}}/g);
      return parts
        .map((part, i) => {
          if (i % 2 === 0) return part;
          const isLegal = isLegalHandlebarAttribute(part, allowedAttributes);
          return `<mark style="background-color: ${isLegal ? "#e3f2fd" : "#ffebee"}">{{${part}}}</mark>`;
        })
        .join("");
    },
    [allowedAttributes]
  );

  const refreshOverlay = useCallback(
    (input: HTMLInputElement) => {
      if (!input.parentElement) return;

      // Remove any existing overlay
      const existingOverlay =
        input.parentElement.querySelector(".highlight-overlay");
      existingOverlay?.remove();

      // Create and show overlay div with highlighting
      const overlay = document.createElement("div");
      overlay.className = "highlight-overlay";
      overlay.innerHTML = handleHandlebarsHighlight(input.value);

      Object.assign(overlay.style, {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        paddingTop: "4px",
        paddingBottom: "5px",
        pointerEvents: "none",
        backgroundColor: "transparent",
        zIndex: "-1", // Place overlay behind input
      });

      input.parentElement.appendChild(overlay);

      // Make the actual input text transparent but keep cursor visible
      input.style.color = "transparent";
      input.style.caretColor = "black";
      input.style.position = "relative"; // Ensure input stays above overlay
      input.style.backgroundColor = "transparent";
    },
    [handleHandlebarsHighlight]
  );

  useMount(() => {
    const input = inputRef.current;
    if (!input) return;

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "value"
        ) {
          refreshOverlay(input);
          break;
        }
      }
    });

    observer.observe(input, {
      attributes: true,
      attributeFilter: ["value"],
    });

    // Initial render
    refreshOverlay(input);

    return () => observer.disconnect();
  });

  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        inputRef: inputRef,
      }}
    />
  );
};

export default HandlebarTextField;
