import { useCallback } from "react";

export const useNativeNotification = () => {
  const showNotification = useCallback(
    (title: string, body: string, silent: boolean, timeout: number) => {
      if (!("Notification" in window)) return;

      if (Notification.permission === "granted") {
        const notification = new Notification(title, {
          icon: "/favicon.ico",
          body,
          silent,
        });

        setTimeout(() => {
          notification.close();
        }, timeout);
      }
    },
    []
  );

  return showNotification;
};
