import { Edit, Publish } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Switch, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { User } from "@tamtam/api/db";
import { createFileRoute } from "@tanstack/react-router";
import { reverse, sortBy, uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { FormDialog } from "../../../components/form-dialog";
import SectionTitle from "../../../components/section-title";
import StatusIcon from "../../../components/status-icon";
import { useUser } from "../../../hooks/use-user";
import { trpc } from "../../../services/trpc";
import AddUser from "../organizations/-components/add-user";
import { EditAdvisorProfile } from "./-components/edit-advisor-profile";

const typeToLabel = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  SUPERVISOR: "Superviseur",
  ADVISOR: "Conseiller",
};

const UsersList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();
  const { data: organization } =
    trpc.organizations.getWithAdvisorProfiles.useQuery({
      id: user.organizationId,
    });
  const enableAdvisor = trpc.organizations.enableAdvisor.useMutation();
  const pushToTop = trpc.advisorProfiles.pushToTop.useMutation();
  const deleteUser = trpc.users.delete.useMutation();

  const handleDelete = async (u: User) => {
    if (
      !window.confirm(
        `Cet utilisateur sera supprimé de manière définitive\n\nÊtes-vous sûr de vouloir supprimer ${u.firstName} ${u.lastName}?`
      )
    ) {
      return;
    }

    try {
      await deleteUser.mutateAsync({ id: u.id });
    } catch {
      enqueueSnackbar("Erreur lors de la suppression de l'utilisateur", {
        variant: "error",
      });
    }
  };

  if (!organization) {
    return null;
  }

  const allUsers = uniqBy(
    [...organization.users, ...organization.admins],
    "id"
  );

  return (
    <div>
      <SectionTitle>Utilisateurs</SectionTitle>
      <Typography variant="h5" sx={{ margin: "30px 0 20px" }}>
        Ajouter un utilisateur
      </Typography>
      <FormDialog
        label="Ajouter"
        title="Ajouter un conseiller"
        render={(close) => (
          <AddUser organizationId={organization.id} onSubmitSuccess={close} />
        )}
      />
      <Typography variant="h6" sx={{ margin: "30px 0 20px" }}>
        Liste des utilisateurs
      </Typography>
      <div style={{ maxWidth: "100%" }}>
        <DataGridPro
          disableColumnMenu
          disableRowSelectionOnClick
          disableColumnSorting
          rows={reverse(
            sortBy(allUsers, (u) => u.advisorProfiles[0].pushedToTopAt)
          )}
          columns={(
            [
              {
                field: "pushToTop",
                headerName: "",
                width: 70,
                renderCell: (params) => (
                  <IconButton
                    color="primary"
                    onClick={() =>
                      pushToTop.mutateAsync({
                        id: params.row.advisorProfiles[0].id,
                      })
                    }
                  >
                    <Publish />
                  </IconButton>
                ),
              },
              {
                field: "available",
                headerName: "Disponible",
                width: 100,
                renderCell: (params) => (
                  <Switch
                    checked={params.row.advisorProfiles[0].enabled}
                    onChange={async (e) => {
                      await enableAdvisor.mutateAsync({
                        organizationId: organization.id,
                        userId: params.row.id,
                        enable: e.target.checked,
                      });
                    }}
                  />
                ),
              },
              {
                field: "displayName",
                headerName: "Nom sur la borne",
                flex: 1,
                valueGetter: (_, user) => user.advisorProfiles[0].displayName,
              },
              {
                field: "email",
                headerName: "Email",
                width: 200,
              },
              ...organization.advisorAttributes.map((attribute) => ({
                field: `attribute_${attribute.id}`,
                headerName: attribute.label,
                flex: 1,
                valueGetter: (
                  _: any, // eslint-disable-line
                  user: User & {
                    advisorProfiles: Array<{
                      advisorAttributeValues: Array<{
                        advisorAttributeId: string;
                        value: string;
                      }>;
                    }>;
                  }
                ) =>
                  user.advisorProfiles[0].advisorAttributeValues.find(
                    (v: { advisorAttributeId: string }) =>
                      v.advisorAttributeId === attribute.id
                  )?.value,
              })),

              {
                field: "firstName",
                headerName: "Prénom",
                width: 130,
              },
              {
                field: "lastName",
                headerName: "Nom",
                width: 130,
              },
              {
                field: "type",
                headerName: "Type",
                width: 130,
                valueGetter: (_, user) => typeToLabel[user.type],
              },
              {
                field: "activated",
                headerName: "Activé",
                width: 100,
                renderCell: (params) => (
                  <StatusIcon checked={!!params.row.passwordHash} />
                ),
              },
              {
                field: "actions",
                headerName: "Actions",
                width: 150,
                renderCell: (params) => (
                  <>
                    <FormDialog
                      label="Editer"
                      title="Editer le conseiller"
                      renderButton={({ open }) => (
                        <IconButton color="primary" onClick={open}>
                          <Edit />
                        </IconButton>
                      )}
                      render={(close) => (
                        <EditAdvisorProfile
                          advisorProfileId={params.row.advisorProfiles[0]?.id} // Because a user has a single advisor profile for a given organization. It's filtered in the prisma query
                          organizationId={organization.id}
                          onSubmitSuccess={close}
                        />
                      )}
                    />
                    {(user.type === "SUPER_ADMIN" ||
                      (user.type === "ADMIN" &&
                        params.row.type === "ADVISOR")) && (
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(params.row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                ),
              },
            ] as GridColDef<(typeof allUsers)[0]>[]
          ).filter(Boolean)}
        />
      </div>
    </div>
  );
};

export const Route = createFileRoute("/dashboard/advisors/")({
  component: UsersList,
});
