import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface StatusIconProps {
  checked: boolean;
}

const StatusIcon: React.FC<StatusIconProps> = ({ checked }) => {
  return checked ? (
    <CheckCircleIcon color="success" />
  ) : (
    <CancelIcon color="error" />
  );
};

export default StatusIcon;
