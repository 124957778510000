import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    AlertColor,
    Box,
    FormControl,
    FormControlLabel,
    Switch
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAsyncFn } from "react-use";

export function NotificationsSection({
  notificationEnabled,
  setNotificationEnabled,
}: {
  notificationEnabled?: boolean;
  setNotificationEnabled: (enabled: boolean) => void;
}) {
  const [state, doAskPermission] = useAsyncFn(async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      window.location.reload();
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {"Notification" in window && Notification.permission === "granted" && (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                sx={{
                  marginRight: 1,
                }}
                size="small"
                checked={notificationEnabled}
                onChange={(e) => {
                  setNotificationEnabled(e.target.checked);
                }}
              />
            }
            label={
              <>
                Les notifications sont{" "}
                <strong>
                  {notificationEnabled ? "activées" : "désactivées"}
                </strong>
              </>
            }
          />
        </FormControl>
      )}

      {"Notification" in window && Notification.permission !== "granted" && (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <div>
            {Notification.permission === "default" ? (
              <LoadingButton
                loading={state.loading}
                variant="contained"
                color="primary"
                onClick={doAskPermission}
              >
                Activer les notifications
              </LoadingButton>
            ) : (
              <span
                style={{
                  color: grey[500],
                }}
              >
                {typeof Notification === "undefined" ? (
                  "Les notifications ne sont pas disponibles dans votre navigateur"
                ) : (
                  <Alert color={"secondary" as AlertColor} severity="warning">
                    Veuillez autoriser les notifications dans les{" "}
                    <strong>paramètres de votre navigateur</strong>
                  </Alert>
                )}
              </span>
            )}
          </div>
        </Box>
      )}
    </Box>
  );
}
