import CallMadeIcon from "@mui/icons-material/CallMade";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CircularProgress,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Organization, User } from "@tamtam/api/db";
import { createFileRoute, Link as RouterLink, useRouter } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FormDialog } from "../../../components/form-dialog";
import SectionTitle from "../../../components/section-title";
import { trpc } from "../../../services/trpc";
import AddOrganization from "./-components/add-organization";

export const Route = createFileRoute("/dashboard/organizations/")({
  component: Organizations,
});

function Organizations() {
  const { data: organizations, isLoading } = trpc.organizations.list.useQuery();
  const { data: users } = trpc.users.list.useQuery();
  const deleteOrganizationMutation = trpc.organizations.delete.useMutation();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const onDelete = (organization: Organization) => async () => {
    if (
      !window.confirm(
        `Tous les utilisateurs de cette organisation vont aussi être supprimés\n\n Êtes-vous sûr de vouloir supprimer ${organization.name}?`
      )
    )
      return;

    await deleteOrganizationMutation.mutateAsync({ id: organization.id });
    enqueueSnackbar("L'organisation a été supprimée", {
      variant: "success",
    });
  };

  return (
    <div>
      <SectionTitle>Organisations</SectionTitle>
      <Typography sx={{ margin: "30px auto 20px auto" }} variant="h6">
        Ajouter une organisation
      </Typography>
      <FormDialog
        label="Créer une organisation"
        title="Créer une organisation"
        render={(close) => (
          <AddOrganization
            onSubmitSuccess={(organizationId) => {
              close();

              router.navigate({
                to: `/dashboard/organizations/${organizationId}`,
              });
            }}
          />
        )}
      />
      <Typography sx={{ margin: "30px auto 20px auto" }} variant="h6">
        Liste des organisations
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : organizations && organizations.length > 0 ? (
        <Table sx={{ minWidth: 700 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "30px" }}></TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Visites (7 derniers jours)</TableCell>
              <TableCell>Dernière visite</TableCell>
              <TableCell>Live</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => {
              const isAlert = organization._count.visits === 0;

              return (
                <TableRow key={organization.id}>
                  <TableCell style={{ width: "30px" }}>
                    <span style={{ color: isAlert ? "red" : "inherit" }}>
                      {isAlert ? "🚨" : ""}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/dashboard/organizations/${organization.id}`}
                    >
                      {organization.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <span
                      style={{
                        color:
                          organization._count.visits === 0 ? "red" : "inherit",
                      }}
                    >
                      {organization._count.visits}
                    </span>
                  </TableCell>
                  <TableCell>
                    {organization.visits[0]?.createdAt ? (
                      <span
                        style={{
                          color:
                            dayjs().diff(
                              organization.visits[0].createdAt,
                              "days"
                            ) > 8
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {dayjs(organization.visits[0].createdAt).fromNow()}
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>
                    <RouterLink
                      to="/live/$id"
                      params={{ id: organization.id }}
                      target="_blank"
                    >
                      <CallMadeIcon color="primary" />
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={onDelete(organization)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Typography>Il n'y a pas encore d'organisation</Typography>
      )}

      <SectionTitle sx={{ marginTop: 8 }}>Utilisateurs</SectionTitle>
      {users?.length ? (
        <DataGridPro
          disableColumnMenu
          disableRowSelectionOnClick
          rows={users}
          columns={[
            {
              field: "name",
              headerName: "Nom",
              flex: 1,
              valueGetter: (_, user: User) =>
                `${user.firstName} ${user.lastName}`,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
            },
            {
              field: "type",
              headerName: "Type",
              flex: 1,
            },
            {
              field: "organization",
              headerName: "Organisation",
              flex: 1,
              valueGetter: (org: Organization) => org.name,
            },
            {
              field: "lastSeenAt",
              headerName: "Dernière connexion",
              flex: 1,
              renderCell: (params) =>
                params.value ? (
                  dayjs(params.value).fromNow()
                ) : (
                  <span style={{ color: "red" }}>Jamais</span>
                ),
            },
            {
              field: "lastWSConnectionAt",
              headerName: "Dernière connexion WS",
              flex: 1,
              renderCell: (params) =>
                params.value ? (
                  dayjs(params.value).fromNow()
                ) : (
                  <span style={{ color: "red" }}>Jamais</span>
                ),
            },
          ]}
        />
      ) : (
        <Typography>Il n'y a pas encore d'utilisateur</Typography>
      )}
    </div>
  );
}
