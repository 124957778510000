import { Box, Button, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { FormDialog } from "../../../../components/form-dialog";
import { useUser } from "../../../../hooks/use-user";
import { RouterOutput, trpc } from "../../../../services/trpc";
import { EditAdvisorProfile } from "../../advisors/-components/edit-advisor-profile";

export function WaitingSettings({
  organization,
}: {
  organization: RouterOutput["organizations"]["get"];
}) {
  const user = useUser();
  const advisorProfileId = user.advisorProfiles.find(
    (a) => a.organizationId === organization?.id
  )?.id;
  const { data: advisorProfile } = trpc.advisorProfiles.get.useQuery(
    {
      id: advisorProfileId!,
    },
    {
      enabled: !!advisorProfileId,
    }
  );

  if (!advisorProfile) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 1,
          }}
        >
          <Typography sx={{ color: "text.secondary" }}>
            Nom sur la borne :
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            {advisorProfile.displayName}
          </Typography>

          {organization.advisorAttributes.map((attribute) => {
            const value = advisorProfile.advisorAttributeValues.find(
              (v) => v.advisorAttributeId === attribute.id
            )?.value;
            return (
              <Fragment key={attribute.id}>
                <Typography sx={{ color: "text.secondary" }}>
                  {attribute.label} :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {value || ""}
                </Typography>
              </Fragment>
            );
          })}
          <FormDialog
            label="Modifier mon profil"
            title="Modifier mon profil"
            renderButton={({ open }) => (
              <Button
                sx={{
                  marginTop: 2,
                }}
                variant="outlined"
                onClick={() => {
                  open();
                }}
              >
                Modifier mon profil
              </Button>
            )}
            render={(close) => (
              <EditAdvisorProfile
                advisorProfileId={advisorProfile.id}
                organizationId={organization.id}
                onSubmitSuccess={close}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
