import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, CircularProgress, Link, Paper, Typography } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";

import { useIdle } from "react-use";
import { trpc } from "../../services/trpc";
import AdvisorPicker from "./-components/advisor-picker";
import Appointment from "./-components/appointment";
import Button from "./-components/button";
import Confirmation from "./-components/confirmation";
import TextField from "./-components/text-field";
import { usePrevious } from "react-use";
import * as Sentry from "@sentry/react";
import Handlebars from "handlebars";
import Markdown from "react-markdown";
export const Route = createFileRoute("/live/$id")({
  component: Live,
});

function Live() {
  const { id } = Route.useParams();
  const {
    data: organization,
    refetch,
    isLoading,
  } = trpc.live.getForLive.useQuery({ id });
  const createVisit = trpc.live.create.useMutation();

  const [step, setStep] = useState<number>();
  const [name, setName] = useState("");
  const [deuxiemeChamps, setDeuxiemeChamps] = useState("");
  const [advisorId, setAdvisorId] = useState<string>();
  const [hasAppointment, setHasAppointment] = useState(false);

  const isIdle = useIdle(20 * 1000);

  const prevIsIdle = usePrevious(isIdle);

  const replaceHandlebarsValues = useCallback(
    (value: string) => {
      try {
        const template = Handlebars.compile(value);
        const advisor = organization?.advisorProfiles.find(
          (a) => a.userId === advisorId
        );
        const values = organization?.advisorAttributes.reduce((acc, a) => {
          const value = advisor?.advisorAttributeValues.find(
            (av) => av.advisorAttributeId === a.id
          )?.value;
          return {
            ...acc,
            [a.key]: value ? `**${value}**` : undefined,
          };
        }, {});

        const result = template(values);

        return <Markdown>{result}</Markdown>;
      } catch (e) {
        Sentry.captureException(e);
        return "";
      }
    },
    [organization?.advisorAttributes, advisorId, organization?.advisorProfiles]
  );

  const reset = useCallback(async () => {
    const { data: org } = await refetch();

    if (org) {
      if (org.config.appointment.activated) {
        setStep(0);
      } else {
        setStep(2);
      }
      setName("");
      setDeuxiemeChamps("");
      setAdvisorId(undefined);
    }
  }, [refetch]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (isIdle && !prevIsIdle) {
      reset();
    }
  }, [isIdle, reset, prevIsIdle]);

  const submit = async () => {
    if (!organization) {
      return;
    }

    try {
      await createVisit.mutateAsync({
        name,
        deuxiemeChamps,
        advisorId: advisorId || null,
        organizationId: organization.id,
      });

      await refetch();

      setStep(4);
    } catch {
      window.alert("Une erreur est survenue, veuillez réessayer");
      setStep(0);
    }
  };

  if (step === undefined || !organization) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <CircularProgress size={50} sx={{ marginBottom: 3 }} />
        <Typography>Chargement de la borne…</Typography>
      </Box>
    );
  }

  const {
    config: {
      assets,
      appointment,
      welcome_title,
      welcome_title_2,
      thank_you,
      label_nom,
      deuxieme_champs_activated,
      deuxieme_champs_label,
      deuxieme_champs_appointment_activated,
      deuxieme_champs_appointment_label,
    },
  } = organization;

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={assets.background}
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress
            size={50}
            sx={{ marginBottom: 3, color: "white" }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        component="img"
        src={assets.background}
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 3,
          margin: 3,
          marginBottom: "23%",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: 3,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {step === 0 && (
            <>
              {welcome_title}
              <br />
              {welcome_title_2}
            </>
          )}
          {step === 1 && (
            <>
              <Button
                action={() => {
                  setAdvisorId(undefined);
                  setName("");
                  setDeuxiemeChamps("");
                  setHasAppointment(false);
                  setStep(0);
                }}
                sx={{
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                  minWidth: "60px",
                  padding: 0,
                  marginRight: "30px",
                }}
              >
                <KeyboardArrowLeftIcon sx={{ fontSize: "2rem" }} />
              </Button>
              {appointment.choose_advisor}
            </>
          )}
          {step === 2 &&
            (appointment.activated ? (
              label_nom
            ) : (
              <>
                {welcome_title}
                <br />
                {welcome_title_2}
                <br />
                {label_nom}
              </>
            ))}
          {step === 3 &&
            (hasAppointment
              ? deuxieme_champs_appointment_label
              : deuxieme_champs_label)}
          {step === 4 &&
            (hasAppointment && appointment.thank_you
              ? replaceHandlebarsValues(appointment.thank_you)
              : thank_you)}
        </Typography>
        {step === 0 && (
          <Appointment
            goToStep={(step) => {
              setStep(step);
              setHasAppointment(step === 1);
            }}
            config={appointment}
          />
        )}
        {step === 1 && (
          <AdvisorPicker
            advisors={organization.advisorProfiles}
            advisorId={advisorId}
            setAdvisorId={setAdvisorId}
            goToStep={setStep}
          />
        )}
        {step === 2 && (
          <TextField
            submit={() =>
              (hasAppointment && deuxieme_champs_appointment_activated) ||
              (!hasAppointment && deuxieme_champs_activated)
                ? setStep(3)
                : submit()
            }
            setValue={setName}
            value={name}
            submitting={createVisit.isPending}
          />
        )}
        {step === 3 && (
          <TextField
            submit={submit}
            setValue={setDeuxiemeChamps}
            value={deuxiemeChamps}
            submitting={createVisit.isPending}
          />
        )}
        {step === 4 && <Confirmation reset={reset} />}
        {appointment.activated && step >= 2 && step <= 3 && (
          <Link
            component="button"
            onClick={() => {
              setAdvisorId(undefined);
              setName("");
              setDeuxiemeChamps("");
              setHasAppointment(false);
              setStep(0);
            }}
            sx={{
              margin: "auto",
              fontSize: "1.7em",
              height: 50,
              width: 150,
              zIndex: 2,
            }}
          >
            Retour
          </Link>
        )}
      </Paper>
    </Box>
  );
}
