import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { useUser } from "./use-user";

export const useCrisp = () => {
  const user = useUser();

  useEffect(() => {
    const email = user?.email;
    const username = user?.firstName + " " + user?.lastName;
    if (email) {
      Crisp.user.setEmail(email);
    }
    if (username) {
      Crisp.user.setNickname(username);
    }
  }, [user?.id, user?.email, user?.firstName, user?.lastName]);

  useEffect(() => {
    Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID);
  }, []);
};
