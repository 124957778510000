import React from "react";
import { Box } from "@mui/material";
import Button from "./button";

interface ConfirmationProps {
  reset: () => void;
}

const Confirmation: React.FC<ConfirmationProps> = ({ reset }) => (
  <Box sx={{ display: "flex", justifyContent: "center" }}>
    <Button
      action={reset}
      sx={{
        marginTop: "30px",
        fontSize: "1.8em",
        height: 100,
        width: 300,
      }}
    >
      OK
    </Button>
  </Box>
);

export default Confirmation;
