import {
  AutoAwesome,
  BarChart as BarChartIcon,
  ExitToApp as ExitToAppIcon,
  Group as GroupIcon,
  GroupOutlined,
  History as HistoryIcon,
  HourglassEmpty as HourglassEmptyIcon,
  LiveHelpOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MUIDrawer,
  Toolbar,
} from "@mui/material";
import { Link, useLocation } from "@tanstack/react-router";
import { Crisp } from "crisp-sdk-web";
import { Fragment, useState } from "react";
import { useUser } from "../hooks/use-user";
import { NewFeaturesDialog } from "../routes/-components/new-features-dialog";

const drawerWidth = 240;

interface MenuItem {
  to: string;
  icon: React.ReactNode;
  primary: string;
  disabled?: boolean;
  target?: string;
  divider?: boolean;
}

const RouterListItem: React.FC<MenuItem> = ({
  to,
  icon,
  primary,
  disabled = false,
  target,
}) => {
  const location = useLocation();
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={to}
        target={target}
        disabled={disabled}
        selected={location.pathname.startsWith(to)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItemButton>
    </ListItem>
  );
};

const itemConfig: Record<string, MenuItem> = {
  settings: {
    to: "/dashboard/settings",
    icon: <SettingsOutlined />,
    primary: "Paramètres",
  },
  advisors: {
    to: "/dashboard/advisors",
    icon: <GroupOutlined />,
    primary: "Utilisateurs",
  },
  archive: {
    to: "/dashboard/archive",
    icon: <HistoryIcon />,
    primary: "Archive",
  },
  waiting: {
    to: "/dashboard/waiting",
    icon: <HourglassEmptyIcon />,
    primary: "Visiteurs",
  },
  statistics: {
    to: "/dashboard/statistics",
    icon: <BarChartIcon />,
    primary: "Statistiques",
    disabled: true,
  },
  live: {
    to: "/live/:organizationId",
    icon: <ExitToAppIcon />,
    primary: "Lancer la borne",
    target: "_blank",
  },
  organizations: {
    to: "/dashboard/organizations",
    icon: <GroupIcon />,
    primary: "Organisations",
  },
};

const menuItems: Record<string, MenuItem[]> = {
  DEFAULT: [
    itemConfig.settings,
    itemConfig.advisors,
    itemConfig.archive,
    { ...itemConfig.statistics, disabled: true },
    itemConfig.waiting,
    itemConfig.live,
  ],
  ADVISOR: [
    itemConfig.waiting,
    { ...itemConfig.statistics, disabled: true },
    itemConfig.live,
  ],
};

export function Drawer() {
  const user = useUser();
  const [showFeaturesDialog, setShowFeaturesDialog] = useState(false);

  return (
    <>
      {showFeaturesDialog && (
        <NewFeaturesDialog
          handleClose={() => setShowFeaturesDialog(false)}
          open={showFeaturesDialog}
        />
      )}
      <MUIDrawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          {user.type === "SUPER_ADMIN" && (
            <RouterListItem {...itemConfig.organizations} />
          )}
          {menuItems[user.type === "ADVISOR" ? "ADVISOR" : "DEFAULT"].map(
            (item) => (
              <Fragment key={item.to}>
                <RouterListItem
                  to={item.to.replace(
                    ":organizationId",
                    user.organizationId ?? ""
                  )}
                  icon={item.icon}
                  primary={item.primary}
                  disabled={item.disabled ?? false}
                  target={item.target}
                />
                {item.divider && <Divider />}
              </Fragment>
            )
          )}
        </List>
        <List sx={{ marginTop: "auto" }}>
          <ListItem key="question" disablePadding>
            <ListItemButton
              onClick={() => {
                setShowFeaturesDialog(true);
              }}
            >
              <ListItemIcon>
                <AutoAwesome />
              </ListItemIcon>
              <ListItemText primary="Nouveautés" />
            </ListItemButton>
          </ListItem>
          <ListItem key="nouveautes" disablePadding>
            <ListItemButton
              onClick={() => {
                Crisp.chat.open();
              }}
            >
              <ListItemIcon>
                <LiveHelpOutlined />
              </ListItemIcon>
              <ListItemText primary="Une question ?" />
            </ListItemButton>
          </ListItem>
        </List>
      </MUIDrawer>
    </>
  );
}
