import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "@tanstack/react-router";
import { SnackbarProvider } from "notistack";
import { useAuth } from "./hooks/use-auth";
import { useInitAnalytics } from "./hooks/use-init-analytics";
import { trpc, TRPCProvider } from "./services/trpc";
import { theme } from "./theme";

import { tanstackRouterBrowserTracingIntegration } from "@sentry/react";
import useMUILicence from "./hooks/use-mui-licence";
import useVersionCheck from "./hooks/use-version-check";
import { router } from "./router";
import { sentry } from "./sentry";

sentry?.addIntegration(tanstackRouterBrowserTracingIntegration(router));

const WithProviders: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <TRPCProvider>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </TRPCProvider>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  useAuth();
  useInitAnalytics();
  useMUILicence();
  useVersionCheck();

  const { data: user } = trpc.auth.me.useQuery();

  if (user === undefined) {
    return null;
  }

  return (
    <>
      <RouterProvider router={router} context={{ user }} />
    </>
  );
};

const AppWithProviders = () => (
  <WithProviders>
    <App />
  </WithProviders>
);

export default AppWithProviders;
