import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { trpc } from "../../../../services/trpc";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";

interface LinkAdminProps {
  organizationId: string;
  onSubmitSuccess: () => void;
}

interface FormInputs {
  email: string;
}

export default function LinkAdmin({
  organizationId,
  onSubmitSuccess,
}: LinkAdminProps) {
  const { control, handleSubmit, setError } = useForm<FormInputs>();
  const { enqueueSnackbar } = useSnackbar();
  const linkAdminMutation = trpc.organizations.linkAdmin.useMutation();

  const onSubmit = async (data: FormInputs) => {
    try {
      await linkAdminMutation.mutateAsync({
        organizationId,
        email: data.email,
      });
      enqueueSnackbar("Administrateur lié avec succès", {
        variant: "success",
      });
      onSubmitSuccess();
    } catch (error) {
      setError("email", {
        type: "manual",
        message:
          error instanceof Error ? error.message : "Une erreur est survenue",
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: "Email is required" }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Email de l'administrateur"
            type="email"
            required
            fullWidth
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
      <Button
        type="submit"
        variant="contained"
        disabled={linkAdminMutation.isPending}
      >
        {linkAdminMutation.isPending ? (
          <CircularProgress size={24} />
        ) : (
          "Lier l'administrateur"
        )}
      </Button>
    </Box>
  );
}
