import { useEffect } from "react";
import { usePrevious } from "react-use";
import { useUser } from "./use-user";
import * as Sentry from "@sentry/react";

export function useInitAnalytics() {
  const user = useUser();
  const prevUser = usePrevious(user);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (prevUser && prevUser.id === user.id) {
      return;
    }

    Sentry.setUser({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      username: `${user.firstName} ${user.lastName}`,
      email: user.email,
      organizationId: user.organizationId,
      type: user.type,
    });
  }, [prevUser, user]);
}
