import { useEffect } from "react";
import { trpc } from "../services/trpc";

export const useAuth = () => {
  const { mutate: setAccessToken } = trpc.auth.setAccessToken.useMutation();

  useEffect(() => {
    const handleToken = async () => {
      const token = localStorage.getItem("feathers-jwt");
      if (token) {
        await setAccessToken({ token });
        localStorage.removeItem("feathers-jwt");
      }
    };

    handleToken();
  }, [setAccessToken]);
};
