import { useEffect } from "react";

const useMUILicence = () => {
  useEffect(() => {
    // Function to locate and remove the unwanted message
    const removeMessage = () => {
      const elements = Array.from(document.querySelectorAll("div")).filter(
        (element) => element.textContent === "MUI X Missing license key"
      );
      elements.forEach((element) => element.remove());
    };

    // Initial run to handle message immediately after first render
    setTimeout(removeMessage, 0);

    // MutationObserver to remove any dynamically inserted message
    const observer = new MutationObserver(removeMessage);
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup on component unmount
    return () => observer.disconnect();
  }, []);
};

export default useMUILicence;
