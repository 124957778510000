import { Box, Toolbar } from "@mui/material";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import AppBar from "../components/app-bar";
import { Drawer } from "../components/drawer";
import { useCrisp } from "../hooks/use-crisp";
import { NewFeaturesDialog } from "./-components/new-features-dialog";
import { useLocalStorage } from "react-use";
import { useWebSocket } from "../hooks/use-websocket";

const currentFeaturesHash = "b8e67dd";

const DashboardLayout = () => {
  useWebSocket();
  useCrisp();
  const [hash, setHash] = useLocalStorage<string>("new_features_dialog_hash");

  const shouldOpenFeaturesDialog = hash !== currentFeaturesHash;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar />
      <Drawer />
      <NewFeaturesDialog
        open={shouldOpenFeaturesDialog}
        handleClose={() => setHash(currentFeaturesHash)}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export const Route = createFileRoute("/dashboard")({
  component: DashboardLayout,
  beforeLoad: async ({ context, location }) => {
    if (!context.user) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
        replace: true,
      });
    }
    if (location.pathname === "/dashboard") {
      throw redirect({
        to: "/dashboard/waiting",
        replace: true,
      });
    }
  },
});
