import { useAtom } from "jotai";
import { useEffect } from "react";
import { websocketAtom } from "../atoms/websocketAtom";

export const useEvent = <T>(eventName: string, callback: (data: T) => void) => {
  const [websocket] = useAtom(websocketAtom);

  useEffect(() => {
    if (!websocket) return;

    const handleMessage = (event: MessageEvent) => {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.event === eventName) {
          callback(parsedData.data as T);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    websocket.addEventListener("message", handleMessage);

    return () => {
      websocket.removeEventListener("message", handleMessage);
    };
  }, [websocket, eventName, callback]);
};
