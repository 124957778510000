import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/test2")({
  component: Test,
});

export function Test() {
  const someArray = ["hello", "world"];
  return (
    <div>
      {/* @ts-expect-error This is a test */}
      {someArray[5].itShouldThrowInsideComponentTree}
    </div>
  );
}
