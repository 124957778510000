import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

interface FormDialogProps {
  render: (handleClose: () => void) => React.ReactNode;
  title: string;
  label?: string;
  renderButton?: (props: { open: () => void }) => React.ReactNode;
}

export function FormDialog({
  render,
  label,
  title,
  renderButton,
}: FormDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {renderButton ? (
        renderButton({ open: handleClickOpen })
      ) : (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {label}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>{render(handleClose)}</DialogContent>
      </Dialog>
    </>
  );
}
