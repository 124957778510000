import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { RouterOutput, trpc } from "../../../../services/trpc";

interface EditAdvisorProfileProps {
  advisorProfileId: string;
  organizationId: string;
  onSubmitSuccess: () => void;
}

const EditAdvisorProfileForm: React.FC<{
  advisorProfile: RouterOutput["advisorProfiles"]["get"];
  organization: RouterOutput["organizations"]["getWithAdvisorAttributes"];
  onSubmitSuccess: () => void;
}> = ({ advisorProfile, organization, onSubmitSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateAdvisorProfile = trpc.advisorProfiles.update.useMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      displayName: advisorProfile.displayName || "",
      advisorAttributeValues: Object.fromEntries(
        organization.advisorAttributes.map((a) => [
          a.id,
          advisorProfile.advisorAttributeValues.find(
            (v) => v.advisorAttributeId === a.id
          )?.id,
        ])
      ),
    },
  });

  const onSubmit = async (values: {
    displayName: string;
    advisorAttributeValues: Record<string, string | undefined>;
  }) => {
    try {
      const advisorAttributeValues = Object.fromEntries(
        Object.entries(values.advisorAttributeValues).filter(
          ([, value]) => value
        )
      ) as Record<string, string>;

      await updateAdvisorProfile.mutateAsync({
        id: advisorProfile.id,
        displayName: values.displayName,
        advisorAttributeValues: Object.values(advisorAttributeValues),
      });
      enqueueSnackbar("Le conseiller a été mis à jour", {
        variant: "success",
      });
      onSubmitSuccess();
    } catch (e) {
      setError("displayName", {
        type: "manual",
        message: (e as Error).message,
      });
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column", gap: 4, minWidth: 300 }}
      >
        <Controller
          name="displayName"
          control={control}
          rules={{ required: "Ce champ est requis" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nom sur la borne"
              required
              error={!!errors.displayName}
              helperText={errors.displayName?.message || ""}
              margin="normal"
            />
          )}
        />

        {organization.advisorAttributes.map((attribute) => (
          <Controller
            key={attribute.id}
            name={`advisorAttributeValues.${attribute.id}`}
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id={`${attribute.id}-label`}>
                  {attribute.label}
                </InputLabel>
                <Select
                  {...field}
                  label={attribute.label}
                  error={!!errors.advisorAttributeValues?.[attribute.id]}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Non renseigné</em>
                  </MenuItem>
                  {attribute.advisorAttributeValues.map((value) => (
                    <MenuItem key={value.id} value={value.id}>
                      {value.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ))}

        {errors.root && (
          <FormHelperText error>{errors.root.message}</FormHelperText>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          sx={{ marginTop: 2, alignSelf: "center" }}
        >
          Valider
        </Button>
      </Box>
    </>
  );
};

export const EditAdvisorProfile: React.FC<EditAdvisorProfileProps> = ({
  advisorProfileId,
  organizationId,
  onSubmitSuccess,
}) => {
  const { data: advisorProfile } = trpc.advisorProfiles.get.useQuery({
    id: advisorProfileId,
  });
  const { data: organization } =
    trpc.organizations.getWithAdvisorAttributes.useQuery({
      id: organizationId,
    });

  if (!advisorProfile || !organization) {
    return null;
  }

  return (
    <>
      <EditAdvisorProfileForm
        advisorProfile={advisorProfile}
        organization={organization}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
};
