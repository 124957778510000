import React from "react";
import { Box, Button } from "@mui/material";

interface AppointmentProps {
  goToStep: (step: number) => void;
  config: {
    label_have?: string;
    label_not_have?: string;
  };
}

const Appointment: React.FC<AppointmentProps> = ({ goToStep, config }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-around",
      "& button": {
        margin: 3,
        fontSize: "1.5em",
        height: 130,
        width: 400,
      },
    }}
  >
    <Button variant="contained" onClick={() => goToStep(1)}>
      {config.label_have || "J'ai un rendez-vous"}
    </Button>
    <Button variant="contained" onClick={() => goToStep(2)}>
      {config.label_not_have || "Je n'ai pas de rendez-vous"}
    </Button>
  </Box>
);

export default Appointment;
