import React from "react";
import { Typography, Box } from "@mui/material";

const SectionTitle: React.FC<{
  children: React.ReactNode;
  sx?: React.CSSProperties;
}> = ({ children, sx }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      marginBottom: 2,
      gap: 4,
      ...sx,
    }}
  >
    <Typography variant="h4">{children}</Typography>
  </Box>
);

export default SectionTitle;
