import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { User, UserType } from "@tamtam/api/db";
import { SubmitHandler, useForm } from "react-hook-form";
import { trpc } from "../../../../services/trpc";
import { useSnackbar } from "notistack";

type IFormInputs = Pick<User, "email" | "firstName" | "lastName" | "type">;

interface AddUserProps {
  organizationId: string;
  onSubmitSuccess: () => void;
}

const keyToLabel: Record<keyof Omit<IFormInputs, "type">, string> = {
  email: "Email",
  firstName: "Prénom",
  lastName: "Nom",
};

const userTypes: { value: UserType; label: string }[] = [
  { value: "ADMIN", label: "Admin" },
  { value: "ADVISOR", label: "Conseiller" },
];

export default function AddUser({
  organizationId,
  onSubmitSuccess,
}: AddUserProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IFormInputs>();
  const createUserMutation = trpc.users.create.useMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    try {
      await createUserMutation.mutateAsync({
        ...data,
        organizationId,
      });
      onSubmitSuccess();
      enqueueSnackbar("L'utilisateur a été créé", {
        variant: "success",
      });
    } catch (e) {
      setError("root", {
        type: "manual",
        message: (e as Error).message,
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ width: "100%", maxWidth: 400 }}
    >
      {Object.entries(keyToLabel).map(([key, label]) => (
        <TextField
          key={key}
          id={key}
          label={label}
          fullWidth
          required
          {...register(key as keyof IFormInputs, {
            required: `${label} est requis`,
          })}
          error={!!errors[key as keyof IFormInputs] || !!errors.root}
          helperText={errors[key as keyof IFormInputs]?.message}
          margin="normal"
        />
      ))}
      <FormControl
        fullWidth
        margin="normal"
        required
        error={!!errors.type || !!errors.root}
      >
        <InputLabel htmlFor="type">Type</InputLabel>
        <Select
          {...register("type", { required: "Le type est requis" })}
          id="type"
          label="Type"
          defaultValue=""
        >
          <MenuItem value="">
            <em>Sélectionner un type</em>
          </MenuItem>
          {userTypes.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
      </FormControl>
      {errors.root && (
        <FormHelperText error sx={{ mt: 2 }}>
          {errors.root.message}
        </FormHelperText>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        fullWidth
        sx={{ mt: 3, mb: 2 }}
      >
        {isSubmitting ? "Création en cours..." : "Créer"}
      </Button>
    </Box>
  );
}
