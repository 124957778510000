import { useCallback, useRef, useEffect } from "react";

export const useSound = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);

  useEffect(() => {
    // Initialize audio elements when the component mounts
    audioRef.current = document.getElementById("alert") as HTMLAudioElement;
    audioContextRef.current = new AudioContext();

    // Cleanup function
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const playSound = useCallback(() => {
    if (!audioRef.current || !audioContextRef.current) {
      console.error("Audio elements not initialized");
      return;
    }

    if (audioContextRef.current.state !== "running") {
      audioContextRef.current.resume();
    }

    audioRef.current.currentTime = 0; // Reset audio to start
    audioRef.current.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  }, []);

  return playSound;
};
