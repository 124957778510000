import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Index,
  beforeLoad: () => {
    throw redirect({
      to: "/login",
      search: {
        redirect: undefined,
      },
      replace: true,
    });
  },
});

function Index() {
  return <div>Hello World</div>;
}

// function Index() {
//   const mail = trpc.test.test.useMutation();
//   const handleTestMail = async () => {
//     const res = await mail.mutateAsync({ message: "hello world" });
//     window.alert(res);
//   };

//   return (
//     <div>
//       <h1>Welcome</h1>
//       <button onClick={handleTestMail}>Test Mail</button>
//     </div>
//   );
// }
