import { Button, FormHelperText, TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { trpc } from "../../../../services/trpc";
import { useSnackbar } from "notistack";

interface IFormInputs {
  name: string;
}

interface AddOrganizationProps {
  onSubmitSuccess: (orgId: string) => void;
}

export default function AddOrganization({
  onSubmitSuccess,
}: AddOrganizationProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<IFormInputs>();
  const createOrganizationMutation = trpc.organizations.create.useMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    try {
      const organizationId = await createOrganizationMutation.mutateAsync(data);
      onSubmitSuccess(organizationId);
      enqueueSnackbar("Nouvelle organisation créée", {
        variant: "success",
      });
    } catch (e) {
      setError("root", {
        type: "manual",
        message: (e as Error).message,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <TextField
        id="name"
        label="Nom"
        required
        {...register("name", { required: "Le nom est requis" })}
        error={!!errors.name || !!errors.root}
        helperText={errors.name?.message}
        margin="normal"
      />
      {errors.root && (
        <FormHelperText error>{errors.root.message}</FormHelperText>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        style={{
          marginTop: "16px",
          alignSelf: "center",
        }}
      >
        Valider
      </Button>
    </form>
  );
}
