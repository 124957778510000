import {
  HourglassEmpty,
  ManageAccounts
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { User, Visit } from "@tamtam/api/db";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useLocalStorage } from "react-use";
import StatusIcon from "../../../components/status-icon";
import { useEvent } from "../../../hooks/use-event";
import { useNativeNotification } from "../../../hooks/use-native-notification";
import { useSound } from "../../../hooks/use-sound";
import { useUser } from "../../../hooks/use-user";
import { trpc } from "../../../services/trpc";
import { AvailableSection } from "./-components/available-section";
import { NotificationsSection } from "./-components/notifications-section";
import { WaitingSettings } from "./-components/waiting-settings";

export const Route = createFileRoute("/dashboard/waiting/")({
  component: Waiting,
});

export type VisibilityMode =
  | "ALL"
  | "ONLY_MINE"
  | "MINE_PLUS_WITHOUT_APPOINTMENT";

function Waiting() {
  const user = useUser();
  const [tab, setTab] = useState<"waiting" | "settings">("waiting");
  const playSound = useSound();
  const showNotification = useNativeNotification();
  const [notificationEnabled, setNotificationEnabled] =
    useLocalStorage<boolean>("visits_sound_enabled", true);
  const [visibilityMode, setVisibilityMode] = useLocalStorage<VisibilityMode>(
    "visits_notification_enabled",
    "MINE_PLUS_WITHOUT_APPOINTMENT"
  );
  const { data: organization } = trpc.organizations.get.useQuery({
    id: user.organizationId,
  });

  const { data: unfilteredVisits, refetch } = trpc.visits.findOpen.useQuery(
    {},
    {
      refetchIntervalInBackground: true,
      refetchInterval: 1000 * 90, // 1 minute et 30 secondes
    }
  );
  const closeVisit = trpc.visits.close.useMutation();
  const { enqueueSnackbar } = useSnackbar();

  useEvent<Visit & { advisor: User }>("visit_created", (data) => {
    if (!organization) return;

    refetch();

    let shouldNotify = false;
    if (
      visibilityMode === "ALL" ||
      !organization.config.appointment.activated
    ) {
      shouldNotify = true;
    } else if (visibilityMode === "ONLY_MINE") {
      shouldNotify = data.advisorId === user.id;
    } else if (visibilityMode === "MINE_PLUS_WITHOUT_APPOINTMENT") {
      shouldNotify = data.advisorId === null || data.advisorId === user.id;
    }

    if (notificationEnabled && shouldNotify) {
      playSound();
      showNotification(
        `${data.name} vient d'arriver`,
        `${data.advisor ? `${data.advisor.firstName} ${data.advisor.lastName}` : "Pas de RDV"}`,
        true,
        8000
      );
    }
  });

  useEvent("visits_updated", () => {
    refetch();
  });

  async function onClose(id: string) {
    if (
      !window.confirm(
        "Ce visiteur sera considéré comme traité\n\nÊtes-vous sûr?"
      )
    ) {
      return;
    }
    await closeVisit.mutateAsync({ visitId: id });
    enqueueSnackbar({
      message: `Le visiteur a été traité`,
      variant: "success",
    });
  }

  if (!organization) return null;

  const visits = unfilteredVisits?.filter((v) => {
    if (!organization.config.appointment.activated) return true;
    if (visibilityMode === "ALL") return true;
    if (visibilityMode === "ONLY_MINE") return v.advisorId === user.id;
    if (visibilityMode === "MINE_PLUS_WITHOUT_APPOINTMENT")
      return v.advisorId === user.id || !v.advisorId;

    return false;
  });

  return (
    <>
      <Typography variant="h4" component="h2" gutterBottom>
        Visiteurs
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 4 }}>
        <Tabs
          value={tab}
          onChange={(_, value) => setTab(value)}
          aria-label="basic tabs example"
        >
          <Tab
            icon={<HourglassEmpty />}
            iconPosition="start"
            label="Visiteurs en attente"
            value="waiting"
          />
          <Tab
            icon={<ManageAccounts />}
            iconPosition="start"
            label="Mon profil sur la borne"
            value="settings"
          />
        </Tabs>
      </Box>

      {tab === "settings" ? (
        user.type !== "SUPER_ADMIN" ? (
          <WaitingSettings organization={organization} />
        ) : (
          <Box
            sx={{
              display: "flex",
              marginBottom: 4,
            }}
          >
            <Alert severity="info">Pas affiché pour les super admins</Alert>
          </Box>
        )
      ) : null}

      {tab === "waiting" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <AvailableSection organization={organization} />
          <NotificationsSection
            notificationEnabled={notificationEnabled}
            setNotificationEnabled={setNotificationEnabled}
          />
          {organization.config.appointment.activated && (
            <FormControl>
              <FormLabel id="visits-visibility-label">Visibilité</FormLabel>
              <RadioGroup
                aria-labelledby="visits-visibility-label"
                value={visibilityMode}
                name="visits-visibility"
                onChange={(e) => {
                  setVisibilityMode(e.target.value as VisibilityMode);
                }}
              >
                {user.type !== "ADVISOR" && (
                  <FormControlLabel
                    value="ALL"
                    control={<Radio />}
                    label={<>Voir toutes les visites</>}
                  />
                )}
                <FormControlLabel
                  value="MINE_PLUS_WITHOUT_APPOINTMENT"
                  control={<Radio />}
                  label={
                    <>Voir mes rendez-vous et les visites sans rendez-vous</>
                  }
                />
                <FormControlLabel
                  value="ONLY_MINE"
                  control={<Radio />}
                  label={<>Voir seulement mes rendez-vous</>}
                />
              </RadioGroup>
            </FormControl>
          )}

          {!visits ? null : visits.length === 0 ? (
            <div
              style={{
                marginTop: "4rem",
                textAlign: "center",
                color: grey[500],
              }}
            >
              Il n'y a pas de visiteur en attente
            </div>
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Alert
                  severity="info"
                  style={{
                    marginTop: 12,
                  }}
                >
                  Le <strong>dernier</strong> visiteur arrivé est en{" "}
                  <strong>bas</strong> de la liste
                </Alert>
              </Box>
              <Table sx={{ minWidth: 800, marginBottom: 40 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    {(organization.config.deuxieme_champs_activated ||
                      organization.config
                        .deuxieme_champs_appointment_activated) && (
                      <TableCell>Champs 2</TableCell>
                    )}
                    <TableCell>Heure d'arrivée</TableCell>
                    {organization.config.appointment.activated && (
                      <>
                        <TableCell>Avec rendez-vous</TableCell>
                        <TableCell>Conseiller</TableCell>
                      </>
                    )}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visits.map(
                    ({ id, name, createdAt, advisor, deuxiemeChamps }) => (
                      <TableRow key={id}>
                        <TableCell>{name}</TableCell>
                        {(organization.config.deuxieme_champs_activated ||
                          organization.config
                            .deuxieme_champs_appointment_activated) && (
                          <TableCell>{deuxiemeChamps}</TableCell>
                        )}
                        <TableCell>{dayjs(createdAt).format("LT")}</TableCell>
                        {organization.config.appointment.activated && (
                          <>
                            <TableCell>
                              <StatusIcon checked={!!advisor} />
                            </TableCell>
                            <TableCell>
                              {advisor
                                ? `${advisor.firstName} ${advisor.lastName}`
                                : ""}
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onClose(id)}
                          >
                            Clore
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default Waiting;
