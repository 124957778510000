import { User } from "@tamtam/api/db";
import {
  createRootRouteWithContext,
  ErrorComponent,
  Outlet,
} from "@tanstack/react-router";
import * as Sentry from "@sentry/react";

type RouterContext = {
  user: User | null;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <Sentry.ErrorBoundary fallback={(e) => <ErrorComponent error={e.error} />}>
      <Outlet />
    </Sentry.ErrorBoundary>
  ),
});
